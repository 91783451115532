import React, {useEffect, useState} from 'react'
import { decode } from 'js-base64'
import {isNull, scrollToElementMiddle} from '@/utils'
import style from './index.module.scss'

import activeIcon from '@/assets/icons/active_item.png'
import unactiveIcon from '@/assets/icons/unactive_item.png'
import iconRight from '@/assets/icons/icon_right.png'
import business from '@/assets/images/queryView/business.png'
import profitability from '@/assets/images/queryView/profitability.png'
import risk from '@/assets/images/queryView/risk.png'
import liquidity from '@/assets/images/queryView/liquidity.png'
import quality from '@/assets/images/queryView/quality.png'
import influences from '@/assets/images/queryView/influences.png'
import focus from '@/assets/images/queryView/focus.png'
import more from '@/assets/images/queryView/rating_green.png'
import less from '@/assets/images/queryView/rating_red.png'
import zero from '@/assets/images/queryView/rating_grey.png'

/**
 * @description: 
 * @param {*} props.tabs [{icon,title,rating,key},...]
 * @return {*}
 */
const TabsDetail = (props) => {
  const {tabs=[], showRating=true, data={}} = props
  const [active, setActive] = useState('')  //当前选中项
  const [tabsItems, setTabsItems] = useState(tabs)  //当前选中项
  const [areaHeight, setAreaHeight] = useState(0)  //当前选中项
  const [paddingHeight,setPaddingHeight] = useState(0)
  
  const tabs_config = {
    FI: {
      '评级基准': 'anchor',
      '业务状况': 'businessProfile',
      '资本与盈利性': 'capitalProfitability',
      '风险状况': 'riskProfile',
      '融资与流动性': 'financingLiquidity',
      '补充调整': 'additionalAdjustment',
      '个体信用质量': 'sacp',
      // '支持方的信用质量': 'supportIcrOrSacp',
      '外部支持重要性': 'externalSupportImportance',
      '外部影响': 'finalNotching',
      '未经调整集团信用质量（如适用）': 'unadjustedSacp',
      '控股公司的特有风险因素（如适用）': 'fhcRisk',
    },
    CORP: {
      '业务状况': 'brp',
      '行业风险': 'industryRisk',
      '竞争地位': 'competitivePosition',
      '财务风险': 'frp',
      '评级基准': 'anchor',
      '个体信用状况': 'sacp',
      '外部影响': 'externalImpact',
      '主体信用评级结果': 'unadjustedSacp',
      '控股公司的特有风险因素（如适用）': 'icr',
    },
    tab_key_map: {
      '业务状况': {key:'business', icon:business },
      '资本与营利性': {key:'profitability', icon:profitability },
      '风险状况': {key:'risk', icon:risk },
      '融资与流动性': {key:'liquidity', icon:liquidity },
      '个体信用质量': {key:'quality', icon:quality },
      '外部影响': {key:'influences', icon:influences },
      '关注点': {key:'focus', icon:focus },
    },
    tabItemStyle: {height:65, marginBottom:5}
  }
  useEffect(()=>{
    if(tabs.length>0) setTabsItems(tabs)
    else initTabsDetail(data)
  },[data])
  useEffect(()=>{
    if(tabsItems.length>0){
      setActive(tabsItems[0].key)
      let height = (tabsItems.length*(tabs_config.tabItemStyle.height+tabs_config.tabItemStyle.marginBottom))-tabs_config.tabItemStyle.marginBottom
      const sections = document.querySelectorAll('.tabsDetail_content_item')
      const lastSections = sections[tabsItems.length - 1].clientHeight
      let pheight = parseInt(height) - parseInt(lastSections)
      setAreaHeight(height)
      setPaddingHeight(pheight)
    }
  },[tabsItems])
  useEffect(()=>{
    const contentContainer = document.getElementById('contentContainer')
    contentContainer.addEventListener('scroll',scrollBodyContent)
  },[])
  /**
   * @description: 监听页面滚动
   */
  const getOffset = (el, offset) => {
    const rect = el.getBoundingClientRect()
    return { top: el.offsetTop - offset, height: rect.height }
  }
  const scrollBodyContent = () => {
    const scrollState = sessionStorage.getItem('scrollState')
    if(scrollState) return
    const contentContainer = document.getElementById('contentContainer')
    const fromTop = contentContainer.scrollTop
    const sections = document.querySelectorAll('.tabsDetail_content_item')
    for(let section of sections){
      const currentOffset = getOffset(section, contentContainer?.offsetTop??60)
      if (fromTop + 3 >= currentOffset.top && fromTop <= currentOffset.top + currentOffset.height) {
        sessionStorage.removeItem('scrollState')
        return setActive(section.id)
      }
    }
  }
  const findNumberForTxt = (txtStr) => {
    const match = txtStr.match(/-?\d+/);
    const number = match ? parseInt(match[0], 10) : null;
    return number
  }
  const initTabsDetail = (data) => {
    if(data?.doc?.analyticalText){
      const tabs = [], map = data?.sector=='金融机构'?tabs_config.FI:tabs_config.CORP;
      (data?.doc?.analyticalText??[]).map(i=>{
        let _item = {title:i.title, key:i.title}
        if(i.contentInBase64){
          _item.content = decode(i.contentInBase64)
        }
        if(map[i.title]){
          const num = findNumberForTxt(data?.doc?.[map[i.title]])
          if(num) {
            _item.rating = num
            _item.areaWidthNum = num%5==0?(num*2)-1:((5-num%5)+num)*2-1
          }
        }
        tabs.push(_item)
      })
      setTabsItems(tabs)
    }
  }
  const handleClick = (event,key) => {
    sessionStorage.setItem('scrollState','1')
    setActive(key)
    event.preventDefault()
    const container = document.getElementById('contentContainer')
    const element = document.getElementById(key)
    if(element){
      scrollToElementMiddle(container, element)
      // element.scrollIntoView({ behavior:'smooth',block:'nearest',inline:'center' })
      setTimeout(()=>{
        sessionStorage.removeItem('scrollState')
      },1000)
    }
  }
  
  return <div className={style.tabsDetail_container}>
    <div className={style.tabsDetail_wrapper}>
      <div className={style.tabsDetail_tabs}>
        { tabsItems.map((tab,index)=>{
            const icon = tabs_config.tab_key_map[tab.title]?.icon??tabs_config.tab_key_map['关注点'].icon
            let offset = 0, ratingLevel = 'zero'
            if(!isNull(tab.rating)) {
              if(tab.rating>0) {
                offset = ((tab.rating+tab.areaWidthNum/2)/tab.areaWidthNum)*100
                ratingLevel = 'more'
              }
              else if(tab.rating<0) {
                offset = ((-tab.rating)/tab.areaWidthNum)*100
                ratingLevel = 'less'
              }
              else offset = 50
            }
            return <div className={`${active==tab.key? style.tabsDetail_tabs_activeitem :''} ${style.tabsDetail_tabs_item} tabsDetail_tabs_item`} key={tab.key} onClick={(event)=> handleClick(event,tab.key)}
              style={{height:tabs_config.tabItemStyle.height,marginBottom:index!=tabsItems.length-1?tabs_config.tabItemStyle.marginBottom:0}}>
              <img src={active==tab.key?activeIcon:unactiveIcon} className={style.tabsDetail_tabs_item_iconRight} alt=''/>
              <img src={icon} className={style.tabsDetail_tabs_item_icon} alt=''/>
              <div className={style.tabsDetail_tabs_item_titleContent}>
                <div className={`${active==tab.key? style.tabsDetail_tabs_item_titleContent_active : ''} ${style.tabsDetail_tabs_item_titleContent_title}`}>{tab.title}</div>
                { showRating && !isNull(tab.rating)
                    ?<div className={style.titleContent_ratingContainer}>
                      <div className={style.titleContent_lineContainer}>
                        <div></div>
                        <span style={{left:0}}></span>
                        <span style={{left:'25%'}}></span>
                        <span style={{left:'50%'}}></span>
                        <span style={{left:'75%'}}></span>
                        <span style={{left:'calc(100% - 1px)'}}></span>
                      </div>
                      { !isNull(tab.rating)
                          ?<div className={style.titleContent_ratingDetail} style={{left: `calc(${offset}% - 5px)`}}>
                            <img src={ratingLevel=='more'?more:ratingLevel=='less'?less:zero} alt=''/>
                            <div className={`${style.titleContent_rating} ${ratingLevel=='more'?style.greenC:ratingLevel=='less'?style.redC:style.greyC}`}>{tab.rating}</div>
                          </div>
                          :null
                      }
                    </div>
                    :null
                }
              </div>
              <img className={style.tabsDetail_tabs_item_detail} src={iconRight} alt=''/>
            </div>
          })
        }
      </div>
      <div style={{borderRadius: "4px",border: "1px solid #C6D4E9",background: "#F5F7FB"}}>
      <div className={style.tabsDetail_content} style={{height:areaHeight,paddingBottom:paddingHeight}}  id="contentContainer">
        { tabsItems.map((tab,idx) => <li className={`${active==tab.key?style.active:''} ${tab.key} tabsDetail_content_item`} style={ idx != (tabsItems && tabsItems?.length - 1) ? {marginBottom:'16px'} : {}} key={tab.key} id={tab.key}>
          <div className={active==tab.key ? `${style.tabsDetail_content_title} ${style.tabsDetail_content_chooseTitle}`:style.tabsDetail_content_title} style={active==tab.key? {marginBottom:"10px"}:{marginBottom: "10px",paddingLeft:"16px"}}>{tab.title}</div>
          <div className={style.tabsDetail_content_detail} style={{padding:"0px 16px 16px 16px"}}>{tab.content}</div>
        </li>) }
      </div>
      </div>
      
    </div>
  </div>
}

export default TabsDetail