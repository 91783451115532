import React, { useEffect, useState } from "react";
import { Graph } from "@antv/x6";
import { register, Portal } from "@antv/x6-react-shape";
import style from "./index.module.scss";
import icon1 from "@/assets/icons/icon1.png";
import { Spin,Tooltip } from "antd";
import icon2 from "@/assets/icons/icon2.png";
const X6ReactPortalProvider = Portal.getProvider(); // 注意，一个 graph 只能申明一个 portal provider
const CurrentIndexContext = React.createContext({
  state: {
    /* 初始状态 */
  },
});
let statusArr = [
  "aaa1",
  "aaa2",
  "aaa3",
  "aaa4",
  "aaa5",
  "aa+1",
  "aa+2",
  "aa",
  "aa-",
  "a+",
  "a",
];
let HYallowArr = ["业务状况", "竞争地位", "财务风险"];
let FIallwoArr = [
  "行业风险",
  "业务状况",
  "经济风险",
  "资本与盈利性",
  "风险状况",
  "融资与流动性",
  "评级基准",
  "集团支持",
  "个体信用状况",
  "政府支持",
  "非公开信用结果分析",
];
const NodeComponent = (props) => {
  let { idx, currentInfo, currentName, currentId, children,old_info } =
    props.node.store.data.data;
  const context = React.useContext(CurrentIndexContext);
  let { currentIndex, nodeClick, isFIType } = context.state;
  let canChoose = true;
  let allowArr = !isFIType ? HYallowArr : FIallwoArr;
  if (!allowArr.includes(currentName)) {
    canChoose = false;
  }
  if (
    currentName == "评级基准" ||
    currentName == "个体信用状况" ||
    currentName == "非公开信用结果分析" ||
    currentName == "行业风险" ||
    currentName == "经济风险" || 
    currentName == "政府支持" ||
    currentName ==  "集团支持"
  ) {
    canChoose = false;
  }
  const getColor = (aItem) => {
    const a_number = aItem.match(/a/gi);
    const b_number = aItem.match(/b/gi);
    if(a_number && a_number?.length) {
      switch (a_number?.length) {
        case 3:
          return '#06927C'
        case 2:
          return '#08C3A5'
        case 1:
          return '#BEDACA'
      }
    }else if(b_number && b_number?.length) {
      switch (b_number?.length) {
        case 3:
          return '#FFAC17'
        case 2:
          return '#FF0000'
        case 1:
          return '#930F4B'
      }
    }else {
      return '#930F4B'
    }
  }
  const getInfo = () => {
    if(currentInfo === '') {
      return;
    }
    let infoId = parseInt(currentInfo);
    if (currentName == '非公开信用结果分析') {
      return <div className={style.nodeOuterFNumber} style={{backgroundColor:getColor(old_info),width:"50px"}}>{old_info}</div>;
    }
    if (infoId >= 0) {
      return (
        <div className={style.nodeOuterZNumber}>
          {currentInfo == 0 ? "" : "+"}
          {currentInfo}
        </div>
      );
    } else if (statusArr.includes(currentInfo)) {
      return <div className={style.nodeOuterZNumber}>{currentInfo}</div>;
    } else {
      return <div className={style.nodeOuterFNumber}>{currentInfo}</div>;
    }
  };
  return (
    <Tooltip placement="right" title={old_info}>
    <div
      onClick={() => {
        if (canChoose) {
          nodeClick(idx, currentId, children, currentName, currentInfo);
        }
      }}
      className={
        currentName == "现金流/财务杠杆"
          ? style.nodeOuter2Style
          : style.nodeOuterStyle
      }
      style={
        !canChoose
          ? {
              backgroundColor: "#F2F3F6",
              borderColor: "#ECF0F1",
              cursor: "not-allowed",
              boxShadow: "none",
            }
          : idx == currentIndex
          ? { borderColor: "#044DCC", background: "#E9F2FF" }
          : { borderColor: "#ECF0F1", background: "#ffffff" }
      }
    >
      <div
        className={style.nodeOuterName}
        style={
          !canChoose
            ? { color: "rgba(0,0,0,0.45)" }
            : idx == currentIndex
            ? { color: "#044DCC" }
            : { color: "rgba(0,0,0,0.85)" }
        }
      >
        {currentName}
      </div>
      {!isFIType
        ? getInfo(currentInfo)
        : allowArr.includes(currentName)
        ? getInfo(currentInfo)
        : null}
      {idx == currentIndex ? (
        <img src={icon1} className={style.nodeOuterImg}></img>
      ) : (
        <img src={icon2} className={style.nodeOuterImg}></img>
      )}
    </div>
    </Tooltip>

  );
};

register({
  width: 114,
  height: 36,
  shape: "org-node",
  component: NodeComponent,
});

register({
  width: 147,
  height: 36,
  shape: "org-node-2",
  component: NodeComponent,
});

// 布局方向
const dir = "LR"; // LR RL TB BT

Graph.registerEdge(
  "org-edge",
  {
    zIndex: -1,
    attrs: {
      line: {
        fill: "none",
        strokeLinejoin: "round",
        strokeWidth: 2,
        stroke: "#A2B1C3",
        sourceMarker: null,
        targetMarker: null,
      },
    },
  },
  true
);

function FlowCpn(props) {
  let { isFIType } = props;
  let timers = [];
  let click_timers = null;
  const [container, setcontainer] = useState(document.createElement("div"));
  const [currentIndex, setCurrentIndex] = useState(null);
  const [graphState, setGraphState] = useState(null);
  const refContainer = (container) => {
    setcontainer(container);
  };
  useEffect(() => {
    let arr = [];
    let linkInfoArr = [];
    const graph = new Graph({
      container: container,
      connecting: {
        router: "normal",
        snap: false,
      },
      panning: {
        enabled: false,
      },
      interacting: false,
    });

    function member(x, y, name, info, id, children,old_info) {
      if (name == "现金流/财务杠杆") {
        return graph.addNode({
          x,
          y,
          shape: "org-node-2",
          data: {
            idx: id,
            currentName: name,
            currentInfo: info,
            currentId: id,
            children: children,
            old_info:old_info
          },
        });
      } else {
        return graph.addNode({
          x,
          y,
          shape: "org-node",
          data: {
            idx: id,
            currentName: name,
            currentInfo: info,
            currentId: id,
            children: children,
            old_info:old_info
          },
        });
      }
    }

    function link(source, target, vertices) {
      return graph.addEdge({
        vertices,
        source: {
          cell: source,
        },
        target: {
          cell: target,
        },
        shape: "org-edge",
      });
    }

    let data = props.flowData;
    data.map((item) => {
      let x = item.x;
      let y = item.y;
      let name = item.name;
      let info = item.info;
      let old_info = item.old_info;
      let id = item.id;
      let children = item.children;
      item.linkInfo.map((ele) => {
        let param = {
          startId: id,
          endId: ele,
        };
        linkInfoArr.push(param);
      });
      let params = member(x, y, name, info, id, children,old_info);
      arr.push(params);
    });

    linkInfoArr.map((ele) => {
      let { startId, endId } = ele;
      link(arr[startId], arr[endId]);
    });

    const edges = graph.getEdges();
    edges.forEach((edge, index) => {
      const source = edge.getSourceNode();
      const target = edge.getTargetNode();
      const sourceBBox = source.getBBox();
      const targetBBox = target.getBBox();
      if ((dir === "LR" || dir === "RL") && sourceBBox.y !== targetBBox.y) {
        const gap =
          dir === "LR"
            ? targetBBox.x - sourceBBox.x - sourceBBox.width
            : -sourceBBox.x + targetBBox.x + targetBBox.width;
        const fix = dir === "LR" ? sourceBBox.width : 0;
        let x = sourceBBox.x + fix + gap / 2;
        edge.setVertices([
          { x, y: sourceBBox.center.y },
          { x, y: targetBBox.center.y },
        ]);
      } else if (
        (dir === "TB" || dir === "BT") &&
        sourceBBox.x !== targetBBox.x
      ) {
        const gap =
          dir === "TB"
            ? targetBBox.y - sourceBBox.y - sourceBBox.height
            : -sourceBBox.y + targetBBox.y + targetBBox.height;
        const fix = dir === "TB" ? sourceBBox.height : 0;
        const y = sourceBBox.y + fix + gap / 2;
        edge.setVertices([
          { x: sourceBBox.center.x, y },
          { x: targetBBox.center.x, y },
        ]);
      } else {
        edge.setVertices([]);
      }
      edge.attr("line", {
        stroke: "rgba(158,188,255,1)", //颜色rgb
        strokeDasharray: 6, //虚线
        targetMarker: {
          name: "classic",
          size: 6, //箭头大小
        },
        strokeWidth: 1, //线条宽度
        style: {
          animation: "ant-line 30s infinite linear", //动画效果
        },
      });
      let timer = setTimeout(() => {
        // edge.attr('line',
        //   {
        //         stroke: "red",  //颜色rgb
        //         strokeDasharray: 6,  //虚线
        //         targetMarker: {
        //             name: 'classic',
        //             size: 6          //箭头大小
        //         },
        //         strokeWidth: 1,    //线条宽度
        //         style: {
        //             animation: 'ant-line 30s infinite linear',  //动画效果
        //         },
        //     })
        edge.attr("line/strokeDasharray", "");
        edge.attr("line/style/animation", "");
        clearTimeout(timers[index]);
      }, index * 200);
      timers.push(timers);
    });
    graph.zoomToFit();
    setGraphState(graph);
  }, [container, props.flowData]);
  useEffect(() => {
    if(!props.chooseZb) {
      setCurrentIndex(null)
    }
  },[props.chooseZb])

  const nodeClick = (e, f, c, d, currentInfo) => {
    if (click_timers) {
      clearTimeout(click_timers);
    }
    const edges = graphState.getEdges();
    edges.forEach((edge, index) => {
      if (c.includes(index)) {
        edge.attr("line", {
          stroke: "rgba(158,188,255,1)", //颜色rgb
          strokeDasharray: 6, //虚线
          targetMarker: {
            name: "classic",
            size: 6, //箭头大小
          },
          strokeWidth: 1, //线条宽度
          style: {
            animation: "ant-line 30s  linear", //动画效果
          },
        });
        let click_timer = setTimeout(() => {
          edge.attr("line/strokeDasharray", "");
          edge.attr("line/style/animation", "");
        }, 1000);
        click_timers = click_timer;
      }
    });
    if (f == currentIndex) {
      props.changeCurrentSelectId(null, null);
      setCurrentIndex(null);
    } else {
      props.changeCurrentSelectId(f, d, undefined, undefined, currentInfo);
      setCurrentIndex(e);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Spin spinning={props.flowLoading}>
        <CurrentIndexContext.Provider
          value={{
            state: {
              currentIndex,
              isFIType,
              nodeClick,
            },
          }}
        >
          <X6ReactPortalProvider />
        </CurrentIndexContext.Provider>
        <div
          id="container"
          style={{ width: "100%", height: "100%" }}
          ref={refContainer}
        ></div>
      </Spin>
    </div>
  );
}
export default FlowCpn;
