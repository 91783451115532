import React, { useEffect, useState } from "react";
import { Empty } from "antd";
import style from "./index.module.scss";
import iconRight from "@/assets/icons/icon_right.png";
import ReactEcharts from "echarts-for-react";
import { getLastYearMonthArray } from "@/utils";
import { useNavigate } from "react-router-dom";
import ModalItem from '@/components/modalItem'
/**
 * @description:
 * @param {*} props.title 标题
 * @param {*} props.type 引用样式类型 detailStyle:详情样式  industryStyle:行业样式
 * @param {*} props.data 数据信息
 * @return {*}
 */
const NewsList = (props) => {
  const navigate = useNavigate();
  const { title = "", type = "1", data = {}, newData = [], isFIType } = props;
  const [open, setOpen] = useState(false);
  const [modalText, setmodalText] = useState("");
  const xAxisData = [
    "aaa1",
    "aaa2",
    "aaa3",
    "aaa4",
    "aaa5",
    "aa+1",
    "aa+2",
    "aa",
    "aa-",
    "a+",
    "a",
    "a-",
    "bbb+",
    "bbb",
    "bbb-",
    "bb+",
    "bb",
    "bb-",
    "b+",
    "b",
    "b-",
    "ccc",
  ];
  const xFAxisData = [
    "aaa1",
    "aaa2",
    "aaa3",
    "aaa4",
    "aaa5",
    "aa+",
    "aa",
    "aa-",
    "a+",
    "a",
    "a-",
    "bbb+",
    "bbb",
    "bbb-",
    "bb+",
    "bb",
    "bb-",
    "b+",
    "b",
    "b-",
    "ccc",
  ];
  const news_config = {
    className: {
      1: "detailStyle",
      2: "industryStyle",
    },
  };

  const getOptions = () => {
    return {
      grid: {
        left: "4%",
        right: "5%",
        bottom: "0%",
        top: "18%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: getCurrentTime(),
        axisLabel: {
          interval: function (index) {
            return index == 11 || index % 3 === 0 ? true : false;
          },
          // rotate: 45    // 旋转标签以节省空间
        },
      },
      tooltip: {},
      yAxis: {
        // type: 'value',
        data: isFIType ? xFAxisData.reverse() : xAxisData.reverse(),
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            color: "#E5E6EB",
          },
        },
        axisLabel: {
          interval: function (index) {
            return index == 21 || index % 3 === 0 ? true : false;
          }, // 控制每隔几个标签显示一次
          // rotate: 45    // 旋转标签以节省空间
        },
      },
      legend: {
        // 对图形的解释部分
        orient: "vertical",
        right: 10,
        y: "top",
        icon: "circle",
        data: ["评级结果"], // 添加
      },
      series: [
        {
          name: "评级结果",
          step: "end",
          data: getXData(),
          type: "line",
          symbol: (rdata, b, c) => {
            if (props.currentChangeIndexArr?.includes(b.name)) {
              return "circle";
            } else {
              return "none";
            }
          },
          symbolSize: 8,
          // markPoint: {
          //   // 这里定义第一个需要显示黄点的位置
          //   data: [
          //     {
          //       name: "2024-01",
          //       coord: ["2024-01", "aa"], // 坐标对应的值
          //       type: "max", // 或者使用type: 'min'或自定义数值
          //       label: {
          //         show: true,
          //         formatter: function (params) {
          //           return params.value;
          //         },
          //       },
          //     },
          //   ],
          // },
          itemStyle: {
            normal: {
              color: "#FD9D13", //改变折线点的颜色
              lineStyle: {
                color: "#FD9D13", //改变折线颜色
              },
            },
          },
        },
      ],
    };
  };

  const getXData = () => {
    return props.XData;
  };

  const getCurrentTime = () => {
    let xData = getLastYearMonthArray();
    return xData;
  };
  const openDetailModal = (record) => {
    setOpen(true);
    setmodalText(record.content);
  };
  return (
    <div className={style.newsList_container}>
      <div className={style.newsList_wrapper}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={`${style.newsList_title} `}>{title}</div>
          <div className={`${style.newsList_secondtitle} `}>评级变动</div>
        </div>
        <div className={style.newsList_content}>
          <div
            className={`${style.mainContent} ${
              style[news_config.className[type]]
            }`}
          >
            <div className={style.mainContent_left}>
              <div className={style.mainContent_num}>
                <span>{newData.length ? newData.length : "-"}</span>条
                <div className={style.mainContent_title}>行业舆情点评</div>
              </div>
            </div>
            <div className={style.newListContentOuter}>
              {newData && newData?.length != 0 ? (
                <div
                  className={`${style.newsListContent} ${
                    style[news_config.className[type]]
                  }`}
                >
                  {newData?.map((news, index) => {
                    return (
                      <li className={style.newsListContent_item} key={index}>
                        <div
                          className={style.newsListContent_item_title}
                          onClick={() => openDetailModal(news)}
                        >
                          {news.title}
                        </div>
                        <div className={style.newsListContent_item_time}>
                          {news.date}
                          <img src={iconRight} alt="" />
                        </div>
                      </li>
                    );
                  })}
                </div>
              ) : (
                <Empty
                  description="暂无数据"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                ></Empty>
              )}
            </div>
          </div>

          <div className={style.newsList_rightCharts}>
            <div className={style.newsList_rightChartsTitle}>等级</div>
            <ReactEcharts
              option={getOptions()} // option：图表配置项
              notMerge={true}
              lazyUpdate={true}
              style={{ height: "171px" }}
            />
          </div>
        </div>
      </div>
      {open && (
        <ModalItem
          visibleState={open}
          title={"详情"}
          modalWidth={800}
          helpContent={false}
          hasFooter={false}
          content={<pre style={{whiteSpace: "pre-line", padding: "0 24px 24px" }}>{modalText}</pre>}
          onCancel={() => setOpen(false)}
        />
      )}
    </div>
  );
};

export default NewsList;
